import { JSONSchema } from "@ngx-pwa/local-storage";

export const officialJSONSchema: JSONSchema = {
    type: 'object',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        phone: { type: 'string' },
        address: { type: 'string' },
        province: {
            type: 'object',
            properties: {
                id: { type : 'string' },
                name: { type : 'string' },
                mapName: { type : 'string' },
                caption: { type : 'string' },
                image: { type : 'string' },
                phone: { type : 'string' },
                zipCode: { type : 'string' },
                isActive: { type: 'boolean' },
                createdAt: { type: 'string' },
                updatedAt: { type: 'string' }
            }
        },
        city: {
            type: 'object',
            properties: {
                id: { type : 'string' },
                name: { type : 'string' },
                mapName: { type : 'string' },
                caption: { type : 'string' },
                image: { type : 'string' },
                phone: { type : 'string' },
                zipCode: { type : 'string' },
                isActive: { type: 'boolean' },
                createdAt: { type: 'string' },
                updatedAt: { type: 'string' }
            }
        },
        district: {
            type: 'object',
            properties: {
                id: { type : 'string' },
                name: { type : 'string' },
                mapName: { type : 'string' },
                caption: { type : 'string' },
                image: { type : 'string' },
                phone: { type : 'string' },
                zipCode: { type : 'string' },
                isActive: { type: 'boolean' },
                createdAt: { type: 'string' },
                updatedAt: { type: 'string' }
            }
        },
        subdistrict: {
            type: 'object',
            properties: {
                id: { type : 'string' },
                name: { type : 'string' },
                mapName: { type : 'string' },
                caption: { type : 'string' },
                image: { type : 'string' },
                phone: { type : 'string' },
                zipCode: { type : 'string' },
                isActive: { type: 'boolean' },
                createdAt: { type: 'string' },
                updatedAt: { type: 'string' }
            }
        },
        zipCode: { type: 'string' },
        latitude: { type: 'string' },
        longitude: { type: 'string' },
        logo: { type: 'string' },
        isActive: { type: 'boolean' },
        createdAt: { type: 'string' },
        updatedAt: { type: 'string' }
    }
};