import { Injectable } from '@angular/core';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  toCommaDecimal(val) {
    return val ? val.toString().replace(/\./g, ',') : null;
  }

  toDotDecimal(val) {
    return val ? parseFloat(val.toString().replace(/,/g, '.')) : null;
  }

  async urlToBase64(imageUrl) {
    if (!imageUrl) {
      imageUrl = 'https://assets.eduku.io/placeholders/schoollogowhenempty.png';
    }

    try {
      var res: any = await fetch(imageUrl, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        referrerPolicy: 'origin',
      });

      if (res) {
        var blob = await res.blob();
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.addEventListener("load", function () {
            resolve(reader.result);
          }, false);

          reader.onerror = () => {
            return reject(this);
          };
          reader.readAsDataURL(blob);
        });
      }
    } catch (err) {
      return null
    }

  }

  numToAlphabet(num) {
    var s = '', t;

    while (num > 0) {
      t = (num - 1) % 26;
      s = String.fromCharCode(65 + t) + s;
      num = (num - t) / 26 | 0;
    }
    return s || undefined;
  }

  parsePlyrLink(url) {
    var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[1].length == 11) {
      return {
        src: match[1],
        provider: 'youtube'
      };
    }

    if (url.split('.').pop() == 'mp4') {
      return {
        src: url,
        type: 'video/mp4'
      }
    }

    return null;
  }

  changeFormatDate(date: string, format: string) {
    return `${moment(date).format(format)}`;
  }
}
