export class Page {
  /**
   * The number of elements in the page
   *
   * @type number
   */
  size: number = 10;
  /**
   * The total number of elements
   *
   * @type number
   */
  totalElements: number = 0;
  /**
   * The total number of pages
   *
   * @type number
   */
  totalPages: number = 0;
  /**
   * The current page number
   *
   * @type number
   */
  pageNumber: number = 0;

  /**
   * Query Search params
   *
   * @type string
   */
  q?: string;

  /**
   * Join params
   *
   * @type Array<string> defined format
   * @example relation||field1,field2
   */
  join: Array<string>;

  /**
   * Filter params
   *
   * @type Array<string> defined format
   * @example field||$eq||value [id||$eq||123]
   */
  filter: Array<string>;

  /**
   * Sort params
   *
   * @type Array<string> defined format
   * @example field,ASC|DESC
   */
  sort: Array<string>;
}
