import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { IDataTable, IStudent } from '../@interfaces';
import { Observable } from 'rxjs';



interface BulkingStudent {
  id: string,
  name: string,
  email: string,
  nik: string,
  nisn: string,
  birthDate: string,
  classes: string,
  level: string,
  password: string,
  status: boolean,
  error: any | string
}

interface ResBulkingStudent {
  status: boolean,
  insertedData: BulkingStudent[],
  failedData: BulkingStudent[]
}

@Injectable({
  providedIn: 'root'
})

export class StudentService {

  constructor(
    private http: HttpClient
  ) { }

  getList(page): Observable<IDataTable<IStudent>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IStudent>>(`${environment.apiEndpoint}student`, { params: param });
  }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}student/${id}`);
  }

  createByCsv(param) {
    return this.http.post(`${environment.apiEndpoint}student/upload-csv`, param);
  }

  createByCsvV2(param) {
    return this.http.post<ResBulkingStudent>(`${environment.apiEndpoint}student/v2/upload-csv`, param);
  }

  update(id, param) {
    return this.http.put(`${environment.apiEndpoint}student/${id}`, param);
  }

  delete(id) {
    return this.http.delete(`${environment.apiEndpoint}student/${id}`);
  }

  activate(id) {
    return this.http.patch(`${environment.apiEndpoint}student/activate/${id}`, {});
  }

  deactivate(id) {
    return this.http.patch(`${environment.apiEndpoint}student/deactivate/${id}`, {});
  }
}
