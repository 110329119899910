import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustSelectDate(controlName: string, dateControlName: string) {
  return (formGroup: FormGroup) => {    
    const control = formGroup.controls[controlName];
    const dateControl = formGroup.controls[dateControlName];

    if (dateControl.errors && !dateControl.errors.mustSelectDate) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    if(control.value && (dateControl.value == '' || !dateControl.value)) {
      dateControl.setErrors({ mustSelectDate: true });      
    }
    else {
      dateControl.setErrors(null);
    }
  }
}
