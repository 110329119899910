import { Component } from '@angular/core';

@Component({
  templateUrl: 'landing.component.html'
})
export class LandingComponent {

  constructor() {}

}
