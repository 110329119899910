import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { NgSelectConfig } from "@ng-select/ng-select";

import { EventService } from "./@services";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template:
    '<router-outlet></router-outlet><ngx-spinner type="pacman"></ngx-spinner>',
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private spiner: NgxSpinnerService,
    private toastr: ToastrService,
    private selectConfig: NgSelectConfig,
    private event: EventService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // Global Events subscriber
    this.event.getObservable().subscribe((event) => {
      if (event.type == "toast") {
        switch (event.toastType) {
          case "error":
            this.toastr.error(event.message, event.title, event.options);
            break;
          case "success":
            this.toastr.success(event.message, event.title, event.options);
            break;
          case "info":
            this.toastr.info(event.message, event.title, event.options);
            break;

          default:
            break;
        }
      }

      if (event.type == "isLoading") {
        if (event.loading) {
          this.spiner.show();
        } else {
          this.spiner.hide();
        }
      }
    });

    this.initSelectConfig();
  }

  initSelectConfig() {
    this.selectConfig.notFoundText = "Tidak ada pilihan yang ditemukan";
    this.selectConfig.appendTo = "body";
  }
}
