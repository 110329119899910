<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{
    src: 'https://assets.eduku.io/brand/eduku_logo.png',
    width: 89,
    height: 25,
    alt: 'Eduku Logo'
  }"
  [navbarBrandMinimized]="{
    src: 'https://assets.eduku.io/brand/eduku_e_logo.png',
    width: 30,
    height: 30,
    alt: 'Eduku Logo'
  }"
  [sidebarToggler]="'lg'"
  [mobileAsideMenuToggler]="false"
  [asideMenuToggler]="false"
>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <i class="icon-bell font-xl"></i>
        <span *ngIf="counter" class="badge badge-pill badge-danger">{{
          counter
        }}</span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <div class="dropdown-header d-flex justify-content-between">
          <strong>Anda punya {{ totalNotif }} notifikasi</strong>
          <a (click)="readAllNotif()">
            <span class="fa fa-check"></span> Baca Semua</a
          >
        </div>
        <ng-container *ngFor="let item of notifications">
          <div class="dropdown-item" (click)="clickNotification(item)">
            <div class="text-truncate font-weight-bold">
              {{ item.title }}
              <sup
                class="text-edu-orange float-right"
                style="top: 4px"
                *ngIf="!item.is_read"
                ><span class="fa fa-circle"></span
              ></sup>
            </div>
            <div class="small text-muted text-truncate">{{ item.message }}</div>
            <small class="text-muted">{{ item.dateString }}</small>
          </div>
        </ng-container>
        <a class="dropdown-item text-center" routerLink="/notifications">
          <strong class="text-edu-green">Lihat Semua Notifikasi</strong>
        </a>
      </div>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <span
          *ngIf="!userProfile?.picture || isImageError"
          class="img-avatar-bg"
        >
          {{ userProfile?.name | username }}
        </span>
        <img
          (error)="isImageError = true"
          *ngIf="userProfile?.picture && !isImageError"
          [src]="userProfile?.picture"
          class="img-avatar"
          alt="admin@eduku.io"
          style="width: 44px; height: 45px"
        />
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" routerLink="/profile"
          ><i class="fa fa-user"></i> Profil</a
        >
        <a class="dropdown-item" routerLink="/setting"
          ><i class="fa fa-wrench"></i> Pengaturan</a
        >
        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"
          ><i class="fa fa-lock"></i> Logout</a
        >
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body bg-gray-100">
  <app-sidebar
    #appSidebar
    [fixed]="true"
    [display]="'lg'"
    [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)"
  >
    <app-sidebar-nav
      [navItems]="navItems"
      [perfectScrollbar]
      [disabled]="appSidebar.minimized"
    ></app-sidebar-nav>
    <ngx-spinner
      [fullScreen]="false"
      type="ball-climbing-dot"
      size="medium"
      name="sidebarSpinner"
    >
    </ngx-spinner>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <cui-breadcrumb> </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer>
  <span class="ml-auto"> Eduku </span>
</app-footer>

<div
  bsModal
  #passwordModal="bs-modal"
  [config]="{ ignoreBackdropClick: true }"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="passwordModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Ubah Password Anda</h4>
      </div>
      <form class="modal-body">
        <div class="form-group">
          <label>Password Baru *</label>
          <div class="input-group">
            <input
              [type]="passEncrypt ? 'password' : 'text'"
              class="form-control"
              [class.is-invalid]="f.password.touched && f.password.errors"
              mask="A*"
              autocomplete="on"
              placeholder="Masukkan Password Baru"
              [formControl]="f.password"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <a (click)="passEncrypt = !passEncrypt">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye': passEncrypt,
                      'fa-eye-slash': !passEncrypt
                    }"
                  ></i>
                </a>
              </span>
            </div>
          </div>
          <div
            *ngIf="f.password.touched && f.password.errors"
            class="invalid-feedback d-block"
          >
            <div *ngIf="f.password.errors.required">
              Password tidak boleh kosong
            </div>
            <div *ngIf="f.password.errors.hasNumber">
              Password harus mengandung angka
            </div>
            <div *ngIf="f.password.errors.hasCapitalCase">
              Password harus mengandung huruf besar
            </div>
            <div *ngIf="f.password.errors.hasSmallCase">
              Password harus mengandung huruf kecil
            </div>
            <div *ngIf="f.password.errors.minlength">
              Minimal {{ f.password.errors.minlength.requiredLength }} karakter
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Konfirmasi Password Baru *</label>
          <div class="input-group">
            <input
              [type]="passConfEncrypt ? 'password' : 'text'"
              class="form-control"
              [class.is-invalid]="
                f.password_conf.touched && f.password_conf.errors
              "
              mask="A*"
              placeholder="Masukkan Konfirmasi Password Baru"
              autocomplete="on"
              [formControl]="f.password_conf"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <a (click)="passConfEncrypt = !passConfEncrypt">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye': passConfEncrypt,
                      'fa-eye-slash': !passConfEncrypt
                    }"
                  ></i>
                </a>
              </span>
            </div>
          </div>
          <div
            *ngIf="f.password_conf.touched && f.password_conf.errors"
            class="invalid-feedback d-block"
          >
            <div *ngIf="f.password_conf.errors.required">
              Konfirmasi Password tidak boleh kosong
            </div>
            <div *ngIf="f.password_conf.errors.minlength">
              Minimal
              {{ f.password_conf.errors.minlength.requiredLength }} karakter
            </div>
            <div *ngIf="f.password_conf.errors.mustMatch">
              Konfirmasi Password tidak sama
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          [tooltip]="tooltip"
          placement="auto"
          triggers=""
          #pop="bs-tooltip"
          containerClass="edu-tooltip"
          (mouseenter)="!form.valid ? pop.show() : pop.hide()"
          (mouseleave)="pop.hide()"
          class="btn btn-edu-green"
          [ngClass]="{ 'cursor-not-allowed': !form.valid }"
          (click)="onSubmit(form.value)"
        >
          Simpan
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltip>
  <p class="m-0 text-left">Pastikan anda sudah mengisi:</p>
  <ul class="pl-4 m-0 text-left">
    <li>Password</li>
    <li>Konfirmasi Password</li>
    <li>Password dan konfirmasi password harus sama</li>
  </ul>
</ng-template>
