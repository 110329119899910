import { ViewChild, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { INavData } from '@coreui/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from "moment";

import { Page } from '../../@models';
import { MustMatch, ValidPassword } from '../../@validators';
import { IUser } from '../../@interfaces';
import { storageConstant } from '../../@constant';
import { EventService, AuthService, NotificationService, UserService, SharedService } from '../../@services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  providers: [SharedService]
})
export class DefaultLayoutComponent {
  @ViewChild('passwordModal') public passwordModal: ModalDirective;

  public sidebarMinimized = false;
  public navItems: INavData[] = [];
  public isReady: boolean = false;

  page = new Page();
  notifications: any = [];
  counter: number = 0;
  totalNotif: number = 0;

  form: FormGroup;
  passEncrypt: boolean = true;
  passConfEncrypt: boolean = true;

  userProfile: any;
  isImageError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private storage: StorageMap,
    private spinner: NgxSpinnerService,
    private event: EventService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userService: UserService,
    private sharedService: SharedService
  ) {
    this.form = this.fb.group({
      'id': [null],
      'password': [null, Validators.compose([
        Validators.required,
        ValidPassword(/\d/, { hasNumber: true }),
        ValidPassword(/[A-Z]/, { hasCapitalCase: true }),
        ValidPassword(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(5)])],
      'password_conf': [null, Validators.compose([Validators.required, Validators.minLength(5)])]
    },
      {
        validators: MustMatch('password', 'password_conf')
      });

    this.getMenu();
    this.getNotifications();
    this.checkUser();
    this.getUserInfo();
    this.validateCurriculum();
    sharedService.changeEmitted$.subscribe(props => {
      switch (props) {
        case "updatedProfile":
          this.getUserInfo();
          break;
        case "updatedNotification":
          this.getNotifications();
          break;

        default:
          break;
      }
    });
  }

  get f() { return this.form.controls; }

  getMenu() {
    this.spinner.show('sidebarSpinner');
    this.storage.get(storageConstant.menus).subscribe((menu: INavData[]) => {
      this.navItems = menu.map((obj: INavData) => {
        if (obj?.children?.length >= 1) {
          const menuName = obj.name.toLocaleLowerCase()
          return {
            name: menuName[0].toUpperCase() + menuName.slice(1),
            icon: obj.icon,
            children: obj.children.map((child: any) => {
              return {
                ...child,
                icon: ''
              }
            }).sort((a, b) => {
              return a.sequence - b.sequence
            })
          }
        } else {
          return obj
        }
      })
      this.isReady = true;
      this.spinner.hide('sidebarSpinner');
    });
  }

  async getNotifications() {
    this.page.filter = [
      'isActive||$eq||true'
    ];
    this.page.sort = ['createdAt,DESC'];
    this.page.size = 10000;

    const data: any = await this.notificationService.getList(this.page).toPromise();
    this.totalNotif = data.total;
    const currentDate = moment();
    this.counter = 0
    const notificationData = data.data.filter((notification, idx) => {
      if (!notification.is_read) {
        this.counter++;
      }

      const createdDate = moment(notification.createdAt);
      const daysDiff = currentDate.diff(createdDate, 'days');

      if (daysDiff > 0) {
        notification.dateString = createdDate.format('D MMM YYYY, HH:mm');
      }
      else {
        const hourDiff = currentDate.diff(createdDate, 'hours');
        const minuteDiff = currentDate.diff(createdDate, 'minutes');

        if (hourDiff > 0) {
          notification.dateString = `${hourDiff} jam yang lalu`;
        }
        else if (minuteDiff > 0) {
          notification.dateString = `${minuteDiff} menit yang lalu`;
        }
        else {
          notification.dateString = 'Baru saja';
        }
      }

      if (idx < 5) {
        return true;
      }

      return false;
    });

    this.notifications = notificationData;
  }

  async getUserInfo() {
    const data: any = await this.userService.getProfile().toPromise();
    this.userProfile = data;
  }

  async clickNotification(item) {
    // Update notification counter
    if (!item.is_read) {
      item.is_read = true;
      await this.notificationService.update(item.id, item).toPromise();
      this.counter--;
    }

    // Redirect to appropriate page
    switch (item.notificationType) {
      case 'task_submission':
        this.router.navigate([`/grading/${item.class.id}/assignment/${item.studentTask.id}`]);
        break;
      case 'classroom_reminder':
        this.router.navigate([`/class-schedules/${item.classroom.id}`]);
        break;
      case 'student_join_request':
        const today = moment();
        const midYear = moment([today.year(), 6]);
        const endYear = moment([today.year(), 11]);

        const filter = {
          class: item.class.id,
          term: today.isSameOrAfter(midYear, 'month') && today.isSameOrBefore(endYear, 'month') ? 1 : 2
        };
        await this.storage.set(storageConstant.homeroomTeacherFilter, filter).toPromise();

        this.router.navigate([`/homeroom-teacher`]);
        break;
      default:
        break;
    }
  }

  async validateCurriculum() {
    const userInfo: any = await this.storage
      .get(storageConstant.userInfo)
      .toPromise();
    const schoolInfo: any = await this.storage
      .get(storageConstant.schoolInfo)
      .toPromise();

    // checking user role
    const isRoleOperator = userInfo?.roles?.find((obj: any) => {
      return obj?.role?.name === "Operator";
    });

    if (isRoleOperator) {
      // checking is user already update the curriculum or not
      const isCurriculumSelected = schoolInfo.curriculums.filter((obj: any) => {
        return obj.isActive;
      });

      if (isCurriculumSelected.length <= 0) {
        this.event.publish({
        type: "toast",
          title: "Info",
          toastType: "info",
          message: "Silahkan update kurikulum anda terlebih dahulu",
          options: {
            positionClass: "toast-bottom-center",
            preventDuplicates: true,
          },
        });
        this.router.navigateByUrl("schools");
      }
    }
  }

  checkUser() {
    this.event.publish({ type: 'isLoading', loading: true });

    this.storage.get(storageConstant.userInfo).subscribe((user: IUser) => {
      if (!user.isPasswordChanged) {
        this.f.id.setValue(user.id);
        this.passwordModal.show();
      }
      this.event.publish({ type: 'isLoading', loading: false });
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  onSubmit(data) {
    if (this.form.valid) {
      this.event.publish({ type: 'isLoading', loading: true });

      this.authService.resetPassword(data.id, data).subscribe((res) => {
        if (res) {
          this.event.publish({
            type: 'toast',
            title: 'Berhasil!',
            toastType: 'success',
            message: 'Password telah dirubah!',
            options: { positionClass: 'toast-bottom-center', preventDuplicates: true }
          });
        }

        this.passwordModal.hide();
        this.event.publish({ type: 'isLoading', loading: false });
      });
    }
  }

  async logout() {
    this.event.publish({ type: 'isLoading', loading: true });
    await this.storage.clear().toPromise();
    this.router.navigate(['login'], { replaceUrl: true });
    this.event.publish({ type: 'isLoading', loading: false });
  }

  async readAllNotif() {
    try {
      const res = await this.notificationService.readAll().toPromise();
      if (res) {
        this.event.publish({
          type: 'toast',
          title: 'Berhasil!',
          toastType: 'success',
          message: 'Semua notifikasi berhasil di baca',
          options: { positionClass: 'toast-bottom-center', preventDuplicates: true }
        });
        this.counter = 0;
        this.getNotifications();
      }
    } catch (err) {
      this.event.publish({
        type: 'toast',
        title: 'Gagal!',
        toastType: 'error',
        message: 'Upss, gagal membaca notifikasi, silahkan coba kembali',
        options: { positionClass: 'toast-bottom-center', preventDuplicates: true }
      });
    }
  }
}
