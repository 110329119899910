// import { utils as XLSXUtils, writeFile } from 'xlsx';
// import { WorkBook, WorkSheet } from 'xlsx/types';
import { utils as XLSXUtils, writeFile } from 'xlsx-js-style';
import { WorkBook, WorkSheet } from 'xlsx-js-style/types';


import { Injectable } from '@angular/core';

export interface IExportAsExcelProps {
  readonly data: any[];
  readonly fileName: string;
  readonly sheetName?: string;
  readonly header?: string[];
  readonly table?: HTMLElement;
}

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  fileExtension = '.xlsx';

  public exportAsExcel({
    data,
    fileName,
    sheetName = 'Data',
    header = [],
    table
  }: IExportAsExcelProps): void {
    let wb: WorkBook;

    if (table) {
      wb = XLSXUtils.table_to_book(table);
    }
    else {
      const ws: WorkSheet = XLSXUtils.json_to_sheet(data, { header });
      wb = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(wb, ws, sheetName);
    }

    writeFile(wb, `${fileName}${this.fileExtension}`);
  }

  public exportAsExcelReport(data, fileName, sheetName = 'Data', merge = null, style = null) {
    let wb: WorkBook;
    let ws: WorkSheet;

    for (let i = 0; i < data.length; i++) {
      if (data[i].type == 'aoa') {
        if (i == 0) {
          ws = XLSXUtils.aoa_to_sheet(data[i].data);
          continue;
        }

        XLSXUtils.sheet_add_aoa(ws, data[i].data, { origin: -1 });
      }
      else if (data[i].type == 'json') {
        if (i == 0) {
          ws = XLSXUtils.json_to_sheet(data[i].data);
          continue;
        }

        XLSXUtils.sheet_add_json(ws, data[i].data, { origin: -1 });
      }
    }

    wb = XLSXUtils.book_new();
    if (merge) {
      ws["!merges"] = merge;
    };
    if (style) {
      if (style.cols) {
        ws["!cols"] = style.cols;
      };
      if (style.rows) {
        ws["!rows"] = style.rows;
      };

      if (style.cellStyles) {
        for (const property in style.cellStyles) {
          ws[`${property}`].s = style.cellStyles[property];
        }
      };

      ws["!margins"] = {
        left: 0.25,
        right: 0.25,
        top: 0.45,
        bottom: 0.75,
        header: 0.3,
        footer: 0.3,
      };
    }
    XLSXUtils.book_append_sheet(wb, ws, sheetName);
    writeFile(wb, `${fileName}${this.fileExtension}`);
  }
}