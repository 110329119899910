import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { IDataTable } from '@interfaces';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  // getList(page) {
  //   const param: any = {
  //     limit: page.size,
  //     page: page.pageNumber
  //   }
  //   if (page.join) {
  //     param.join = page.join;
  //   }
  //   if (page.q) {
  //     param.s = page.q;
  //   }
  //   if (page.filter) {
  //     param.filter = page.filter;
  //   }
  //   if (page.sort) {
  //     param.sort = page.sort;
  //   }

  //   return this.http.get<IDataTable>(`${environment.apiEndpoint}staff`, { params: param });
  // }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}user/${id}`);
  }

  update(id, param) {
    return this.http.patch(`${environment.apiEndpoint}user/${id}`, param);
  }

  getProfile() {
    return this.http.get(`${environment.apiEndpoint}profile`);
  }
}
