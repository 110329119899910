import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

// custom validator to check that two fields match
export function ValidDateRange(firstControlName: string, secondControlName: string) {
  return (formGroup: FormGroup) => {    
    const firstControl = formGroup.controls[firstControlName];
    const secondControl = formGroup.controls[secondControlName];

    if ((firstControl.errors && !firstControl.errors.invalidDateRange) || (secondControl.errors && !secondControl.errors.invalidDateRange)) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    const startDateTime = moment(firstControl.value);
    const endDateTime = moment(secondControl.value);

    if(startDateTime.isSameOrAfter(endDateTime)) {
      firstControl.setErrors({ invalidDateRange: true });
      secondControl.setErrors({ invalidDateRange: true });
    }
    else if(endDateTime.isSameOrBefore(startDateTime)) {
      firstControl.setErrors({ invalidDateRange: true });
      secondControl.setErrors({ invalidDateRange: true });
    }
    else {
      firstControl.setErrors(null);
      secondControl.setErrors(null);
    }
  }
}
