import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { IDataTable, IAcademicYear } from '../@interfaces';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AcademicYearService {

  constructor(
    private http: HttpClient
  ) { }

  getList(page): Observable<IDataTable<IAcademicYear>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IAcademicYear>>(`${environment.apiEndpoint}tahun-ajaran`, { params: param });
  }
  
  getActiveList() {
    return this.http.get(`${environment.apiEndpoint}tahun-ajaran/list-active`);
  }

  // get(id) {
  //   return this.http.get(`${environment.apiEndpoint}school/${id}`);
  // }

  // update(id, params) {
  //   return this.http.patch(`${environment.apiEndpoint}school/update/${id}`, params);
  // }

  // activate(params) {
  //   return this.http.put(`${environment.apiEndpoint}school/activate`, params);
  // }

  // deactivate(params) {
  //   return this.http.put(`${environment.apiEndpoint}school/deactivate`, params);
  // }

}
