import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Report } from '../@models';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(
    private helper: HelperService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  generatePdf(content, filename = 'file.pdf') {
    pdfMake.createPdf(content).download(filename);
  }

  async generatePdfReport(data: Report) {
    const schoolName = data.school.name;
    const schoolAddress = `${data.school.address} ${data.school.district.name}`;
    const schoolAdditionalAddress = `${data.school.city.name} - ${data.school.province.name}`;
    const schoolLogo = await this.helper.urlToBase64(data.school.logo);
    const edukuLogo = await this.helper.urlToBase64('https://assets.eduku.io/brand/eduku_logo_transparent_md.png');
    const watermarkLogo = await this.helper.urlToBase64('https://assets.eduku.io/brand/eduku_logo_watermark.png');

    let school = {};
    if (schoolLogo) {
      school = { image: schoolLogo, width: 50 };
    }
    let eduku: any = { text: 'EDUKU', fontSize: 15, bold: true, color: '#00D196', characterSpacing: 5, lineHeight: 2 };
    if (edukuLogo) {
      eduku = { image: edukuLogo, width: 70 };
    }



    var content = {
      pageSize: data.size,
      pageMargins: [20, 80, 20, 60],
      header: (currentPage, pageCount, pageSize) => {
        if (!data.repeatHeader && currentPage > 1) {
          return;
        }

        return [
          {
            columns: [
              {
                width: '15%',
                stack: [
                  // { image: schoolLogo, width: 50 }
                  school
                ],
                alignment: 'center'
              },
              {
                width: '70%',
                text: [
                  { text: `${schoolName}\r\n`, bold: true, fontSize: 14 },
                  { text: `${schoolAddress}\r\n`, fontSize: 12 },
                  { text: schoolAdditionalAddress, fontSize: 12 }
                ],
                alignment: 'center'
              },
              {
                width: '15%',
                text: ''
              }
            ],
            margin: [20, 20, 20, 0]
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 20, y1: 10,
                x2: pageSize.width - 20, y2: 10,
                lineWidth: 1
              }
            ]
          }
        ]
      },
      footer: (currentPage, pageCount, pageSize) => {
        return [
          {
            canvas: [
              {
                type: 'line',
                x1: 20, y1: 0,
                x2: pageSize.width - 20, y2: 0,
                lineWidth: 1
              }
            ]
          },
          {
            columns: [
              {
                width: '85%',
                text: ''
              },
              {
                width: '15%',
                stack: [
                  { text: 'Powered by', fontSize: 8 },
                  // { image: edukuLogo, width: 70 }
                  eduku
                ]
              }
            ],
            margin: [20, 10, 20, 0]
          }
        ]
      },
      background: (currentPage, pageSize) => {
        if (data.showWatermark && watermarkLogo) {
          return {
            image: watermarkLogo,
            width: 400,
            absolutePosition: {
              x: (pageSize.width / 2) - 200, y: (pageSize.height / 2) - 200
            }
          }
        }
      },
      content: data.content,
      defaultStyle: {
        fontSize: 10
      },
      styles: {
        tableHeader: {
          bold: true,
          color: 'white'
        },
        tableBody: {
          border: [false, false, false, false]
        }
      }
    }

    pdfMake.createPdf(content).download(data.filename);
  }
}
