import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CustomHttpParamEncoder } from "../@filters";

import { environment } from "../../environments/environment";
import {
  IDataTable,
  IClassroom,
  IClassroomDiscussion,
  IClassroomAttendance,
} from "../@interfaces";
import { Observable } from "rxjs";
import { EventService } from "./event.service";

@Injectable({
  providedIn: "root",
})
export class ClassroomService {
  constructor(private http: HttpClient, private event: EventService) {}

  getList(page): Observable<IDataTable<IClassroom>> {
    var param = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    param = param.append("limit", page.size);
    param = param.append("page", page.pageNumber);
    if (page.join) {
      for (let i = 0; i < page.join.length; i++) {
        param = param.append("join", page.join[i]);
      }
    }
    if (page.q) {
      param = param.append("s", page.q);
    }
    if (page.filter) {
      for (let i = 0; i < page.filter.length; i++) {
        param = param.append("filter", page.filter[i]);
      }
    }
    if (page.sort) {
      for (let i = 0; i < page.sort.length; i++) {
        param = param.append("sort", page.sort[i]);
      }
    }

    return this.http.get<IDataTable<IClassroom>>(
      `${environment.apiEndpoint}class-room`,
      { params: param }
    );
  }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}class-room/${id}`);
  }

  create(param) {
    return this.http.post(`${environment.apiEndpoint}class-room/create`, param);
  }

  update(id, param) {
    return this.http.patch(`${environment.apiEndpoint}class-room/${id}`, param);
  }

  delete(id) {
    return this.http.delete(`${environment.apiEndpoint}class-room/${id}`);
  }

  info(id) {
    return this.http.get(`${environment.apiEndpoint}class-room/info/${id}`);
  }

  getDuplicateSchedule(params) {
    return this.http.get(
      `${environment.apiEndpoint}class-room/simulation/calculate-schedule`,
      { params: params }
    );
  }

  getDiscussionList(page): Observable<IDataTable<IClassroomDiscussion>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IClassroomDiscussion>>(
      `${environment.apiEndpoint}class-room-discussion`,
      { params: param }
    );
  }

  createDiscussion(param) {
    return this.http.post(
      `${environment.apiEndpoint}class-room/discussion`,
      param
    );
  }

  getAttendanceList(page): Observable<IDataTable<IClassroomAttendance>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IClassroomAttendance>>(
      `${environment.apiEndpoint}class-room-attendance`,
      { params: param }
    );
  }

  getStudentAttendanceList(id) {
    return this.http.get(
      `${environment.apiEndpoint}class-room/attendance/list/${id}`
    );
  }

  updateStudentAttendanceList(id, params) {
    return this.http.put(
      `${environment.apiEndpoint}class-room/attendance/update/${id}`,
      params
    );
  }

  getStaffAttendance(id) {
    return this.http.get(
      `${environment.apiEndpoint}class-room/attendance/staff/${id}`
    );
  }

  createStaffAttendance(params) {
    return this.http.post(
      `${environment.apiEndpoint}staff/absences/present`,
      params
    );
  }
}
