import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class ACLService {

  constructor(
    private http: HttpClient,
  ) { }

  getGroupRoles(userType) {
    const param = {
      filter: `type||$cont||${userType}`
    };
    return this.http.get(`${environment.apiEndpoint}group-roles`, { params: param });
  }

  getRoles(grup) {
    const param = {
      filter: `groups.group.id||$eq||${grup}`
    };
    return this.http.get(`${environment.apiEndpoint}roles`, { params: param });
  }

  getCurrentMenu() {
    return this.http.get(`${environment.apiEndpoint}modules/me`);
  }

  getMenuByUserId(id) {
    return this.http.get(`${environment.apiEndpoint}user/menu/${id}`);
  }  

  getModuleByRoles(roleIdx: any[]) {

    return this.http.post(`${environment.apiEndpoint}modules/roleIdx`, roleIdx);
  }

  flaternRole(roles: any[]) {
    return _.map(_.filter(roles, { isActive: true }), 'id');
  }

}
