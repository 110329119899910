import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { DashboardFilter } from '../@models/dashboard-filter';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient,
  ) { }

  getDashboardTotal() {
    return this.http.get(`${environment.apiEndpoint}dashboard/guru/info-total`);
  }

  getHeadmasterDashboardTotal() {
    return this.http.get(`${environment.apiEndpoint}dashboard/headmaster/info-total`);
  }

  getHeadmasterClassSchedules() {
    return this.http.get(`${environment.apiEndpoint}dashboard/headmaster/info-classroom`);
  }

  getHeadmasterTeacherAttendance() {
    return this.http.get(`${environment.apiEndpoint}dashboard/headmaster/teacher-attendance`);
  }

  getHeadmasterStudentAttendance(classId) {
    return this.http.get(`${environment.apiEndpoint}dashboard/headmaster/student-attendance/${classId}`);
  }

  getOfficialTotalSchools(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/total-schools`, { params: param });
  }

  getOfficialTotalTeachers(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/total-teachers`, { params: param });
  }

  getOfficialTotalStudents(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/total-students`, { params: param });
  }

  getOfficialTotalUsers(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/total-users`, { params: param });
  }

  getOfficialDistributionUsersByLocation(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/distribution-users-by-location`, { params: param });
  }

  getOfficialDistributionUsersByClass(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/distribution-users-by-level-class`, { params: param });
  }

  getOfficialTotalAttendances(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/total-attendances`, { params: param });
  }

  getOfficialTotalAssignments(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/total-assignments`, { params: param });
  }

  getOfficialAverageGradesByCourse(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/average-grades-by-course`, { params: param });
  }

  getTopSchoolByUser(param) {
    return this.http.get(`${environment.apiEndpoint}dashboard/official/top-school-by-user`, { params: param });
  }
}