import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { IDataTable, IClass } from '../@interfaces';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ClassService {

  constructor(
    private http: HttpClient
  ) { }

  getList(page): Observable<IDataTable<IClass>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IClass>>(`${environment.apiEndpoint}class`, { params: param });
  }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}class/${id}`);
  }

  create(param) {
    return this.http.post(`${environment.apiEndpoint}class/create`, param);  
  }

  update(id, param) {
    return this.http.patch(`${environment.apiEndpoint}class/update/${id}`, param);
  }
  

  getListBySchool(schoolId : string) {
    return this.http.get(`${environment.apiEndpoint}class/list-by-school/${schoolId}`);
  }

}
