import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { IDataTable, INotification } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ) { }

  getList(page): Observable<IDataTable<INotification>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<INotification>>(`${environment.apiEndpoint}me/notifications`, { params: param });
  }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}notifications/${id}`);
  }

  create(param) {
    return this.http.post(`${environment.apiEndpoint}notifications`, param);
  }

  update(id, param) {
    return this.http.patch(`${environment.apiEndpoint}notifications/${id}`, param);
  }

  readAll() {
    return this.http.post(`${environment.apiEndpoint}me/notifications/read-all`, {})
  }

}
