import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { IDataTable, IStudentAssignmentComment } from '../@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GradingService {

  constructor(
    private http: HttpClient,
  ) { }

  getClassList() {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/class-room`);
  }

  getClassroomAssignments(params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/clasroom-task`, { params: params });  
  }

  getAssignments(params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/task`, { params: params });  
  }

  getAssignment(id) {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/task/${id}`);
  }

  updateAssignment(id, params) {
    return this.http.put(`${environment.apiEndpoint}penilaian/task/save/${id}`, params);
  }

  getAssignmentCommentList(page): Observable<IDataTable<IStudentAssignmentComment>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IStudentAssignmentComment>>(`${environment.apiEndpoint}student-task-comment`, { params: param });
  }

  createAssignmentComment(param) {
    return this.http.post(`${environment.apiEndpoint}student-task-comment`, param);
  }

  getClassroomQuizzes(params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/clasroom-quiz`, { params: params });  
  }

  getQuizzes(params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/quiz`, { params: params });
  }

  getQuiz(id) {
    return this.http.get(`${environment.apiEndpoint}penilaian/get/quiz/${id}`);
  }

  updateQuiz(id, params) {
    return this.http.put(`${environment.apiEndpoint}penilaian/quiz/save/${id}`, params);
  }

  resetQuiz(id, params) {
    return this.http.patch(`${environment.apiEndpoint}student/quiz/reset/${id}`, params);
  }

  getCourseList(id) {
    return this.http.get(`${environment.apiEndpoint}penilaian/siswa/course/${id}`);
  }

  getStudentReport(id, params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/siswa/report/${id}`, { params: params });
  }

  getStudentList(id, params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/siswa/list/${id}`, { params: params });
  }

  getStudent(id, params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/siswa/detail/${id}`, { params: params });
  }

  getStudentExamDetail(id, params) {
    return this.http.get(`${environment.apiEndpoint}penilaian/siswa/detail-exam/${id}`,  { params: params });
  }
  
  updateStudentFinalScore(id, payload) {
    return this.http.put(`${environment.apiEndpoint}penilaian/siswa/final-score-exam/${id}`, payload);
  }

  updateStudent(id, params) {
    return this.http.put(`${environment.apiEndpoint}penilaian/siswa/final-score/${id}`, params);
  }

}
