import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { IDataTable } from '../@interfaces';
import { Observable } from 'rxjs';
import { ICourse } from '../@interfaces/course/course.interface';

@Injectable({
    providedIn: 'root'
})

export class CourseService {

  constructor(
    private http: HttpClient
  ) { }

  getList(page): Observable<IDataTable<ICourse>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<ICourse>>(`${environment.apiEndpoint}course`, { params: param });
  }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}course/${id}`);
  }

  getAllList(schoolId) {
    const param: any = {
      page: 1,
      limit: 1000,
      schoolId
    }
    return this.http.get(`${environment.apiEndpoint}course/list`, {params: param});
  }

  create(param) {
    return this.http.post(`${environment.apiEndpoint}course/create`, param);  
  }

  update(id, param) {
    return this.http.patch(`${environment.apiEndpoint}course/update/${id}`, param);
  }

  getCourseBySchoolId(id: string, page: any) {
    const param: any = {
      limit: page.size,
      page: page.pageNumber
    }
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }
    if (id) {
      param.schoolId = id;
    }
    return this.http.get(`${environment.apiEndpoint}course/list`, { params: param });
  }

}
