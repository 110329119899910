import { NgModule } from '@angular/core';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { idLocale } from 'ngx-bootstrap/locale';

import * as moment from 'moment';

@NgModule()
export class AppLocaleModule {
  constructor() {
    defineLocale('id', idLocale);

    // Set moment and datepicker locale
    moment.locale('id');
  }
}
