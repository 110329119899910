import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { IAuth } from '@interfaces';
import { IResponse, IStatusGoogle } from '../@interfaces';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  login(data) {
    return this.http.put<IAuth>(`${environment.apiEndpoint}auth/login`, data);
  }

  register(data) {
    return this.http.post(`${environment.apiEndpoint}auth/register`, data);
  }

  verify(data) {
    return this.http.patch(`${environment.apiEndpoint}auth/user-verification/${data}`, {});
  }

  changePassword(id, params) {
    return this.http.patch(`${environment.apiEndpoint}auth/change-password/${id}`, params);
  }

  resetPassword(id, params) {
    return this.http.patch(`${environment.apiEndpoint}auth/reset-password/${id}`, params);
  }

  forgotPassword(params) {
    return this.http.patch(`${environment.apiEndpoint}auth/forgot-password`, params);
  }

  googleLogin() {
    return this.http.get(
      `${environment.apiEndpoint}auth/google-login`
    );
  }

  refreshUserData() {
    return this.http.get(
      `${environment.apiEndpoint}auth/me`
    );
  }

  verifyGoogleLogin(params) {
    return this.http.get(
      `${environment.apiEndpoint}auth/google-login/redirect`,
      { params: params }
    );
  }

  verifyGoogleUser(params) {
    return this.http.get(
      `${environment.apiEndpoint}auth/google-login/verify-user`,
      { params: params }
    );
  }

  googleStatus() {
    return this.http.get<IStatusGoogle>(
      `${environment.apiEndpoint}auth/google-connect/status-google`
    );
  }

  connectWithGoogle(params) {
    return this.http.get<IResponse>(
      `${environment.apiEndpoint}auth/google-connect/link-google`, { params: params }
    );
  }

  removeGoogle() {
    return this.http.get<IResponse>(
      `${environment.apiEndpoint}auth/google-connect/remove-google`
    );
  }
}
