import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  IDataTable,
  ILessonPlan,
  ILessonPlanIndicator,
  IQuiz,
  IAssignment,
} from "../@interfaces";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RPPService {
  constructor(private http: HttpClient) {}

  getList(page): Observable<IDataTable<ILessonPlan>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<ILessonPlan>>(
      `${environment.apiEndpoint}rpp`,
      { params: param }
    );
  }

  get(id) {
    return this.http.get(`${environment.apiEndpoint}rpp/${id}`);
  }

  create(param) {
    return this.http.post(`${environment.apiEndpoint}rpp/create`, param);
  }

  update(id, param) {
    return this.http.patch(`${environment.apiEndpoint}rpp/update/${id}`, param);
  }

  delete(id) {
    return this.http.patch(
      `${environment.apiEndpoint}rpp/deactivate/${id}`,
      {}
    );
  }

  getIndicatorList(page): Observable<IDataTable<ILessonPlanIndicator>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<ILessonPlanIndicator>>(
      `${environment.apiEndpoint}rpp-indicator`,
      { params: param }
    );
  }

  createIndicator(param) {
    return this.http.post(`${environment.apiEndpoint}rpp-indicator`, param);
  }

  updateIndicator(id, param) {
    return this.http.patch(
      `${environment.apiEndpoint}rpp-indicator/${id}`,
      param
    );
  }

  deleteIndicator(id) {
    return this.http.patch(
      `${environment.apiEndpoint}rpp-indicator/deactivate/${id}`,
      {}
    );
  }

  getQuizList(page): Observable<IDataTable<IQuiz>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IQuiz>>(`${environment.apiEndpoint}quiz`, {
      params: param,
    });
  }

  getQuiz(id) {
    return this.http.get(`${environment.apiEndpoint}quiz/${id}`);
  }

  createQuiz(param) {
    return this.http.post(`${environment.apiEndpoint}quiz/create`, param);
  }

  createDraftQuiz(param) {
    return this.http.post(`${environment.apiEndpoint}quiz/create-draft`, param);
  }

  createQuizWithCode(param) {
    return this.http.post(
      `${environment.apiEndpoint}quiz/create-with-code`,
      param
    );
  }

  updateQuiz(id, param) {
    return this.http.patch(
      `${environment.apiEndpoint}quiz/update/${id}`,
      param
    );
  }

  updateDraftQuiz(id, param) {
    return this.http.patch(
      `${environment.apiEndpoint}quiz/update-draft/${id}`,
      param
    );
  }

  deleteQuiz(id) {
    return this.http.patch(
      `${environment.apiEndpoint}quiz/deactivate/${id}`,
      {}
    );
  }

  getQuestionDetail(id) {
    return this.http.get(`${environment.apiEndpoint}quiz-questions/${id}`);
  }

  editQuestion(id, payload) {
    return this.http.put(
      `${environment.apiEndpoint}quiz-questions/${id}`,
      payload
    );
  }

  getAssignmentList(page): Observable<IDataTable<IAssignment>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IAssignment>>(
      `${environment.apiEndpoint}task`,
      { params: param }
    );
  }

  getAssignment(id) {
    return this.http.get(`${environment.apiEndpoint}task/${id}`);
  }

  createAssignment(param) {
    return this.http.post(`${environment.apiEndpoint}task/create`, param);
  }

  createDraftAssignment(param) {
    return this.http.post(`${environment.apiEndpoint}task/create-draft`, param);
  }

  updateAssignment(id, param) {
    return this.http.patch(
      `${environment.apiEndpoint}task/update/${id}`,
      param
    );
  }

  updateDraftAssignment(id, param) {
    return this.http.patch(
      `${environment.apiEndpoint}task/update-draft/${id}`,
      param
    );
  }

  deleteAssignment(id) {
    return this.http.patch(
      `${environment.apiEndpoint}task/deactivate/${id}`,
      {}
    );
  }

  validateQuizCode(params: any) {
    const param = this.convertParam(params);
    return this.http.get(
      `${environment.apiEndpoint}quiz/code-quiz`,
      { params: param }
    );
  }
  createQuizByCode(payload) {
    return this.http.post(
      `${environment.apiEndpoint}quiz/create-with-code`,
      payload
    );
  }

  convertParam(params: any) {
    let param: any = {};
    Object.keys(params).forEach((key: string) => {
      if (params[key]) {
        param[key] = params[key];
      }
    });
    return param;
  }
}
