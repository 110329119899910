export const storageConstant = {
    tokenKey: 'auth-token',
    userInfo: 'user-information',
    schoolInfo: 'school-information',
    officialInfo: 'official-information',
    menus: 'menus',
    pageIds: 'page-ids',
    gradingPageIds: 'grading-page-ids',
    courseIds: 'course-ids',
    rppData: 'rpp-data',
    homeroomTeacherFilter: 'homeroom-teacher-filter',
    finalGradingFilter: 'final-grading-filter',
    gradingFilter: 'grading-filter',
    gradingExamStudentpageIds: 'grading-exam-student-pageIds'
};
