import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, PermissionGuard } from './@guards';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LandingComponent } from './pages/landing/landing.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: "verify-google",
    loadChildren: () =>
      import("./pages/google/google.module").then((m) => m.GoogleModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'user-verify',
    loadChildren: () => import('./pages/verification/verification.module').then(m => m.VerificationModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/verification/verification.module').then(m => m.VerificationModule)
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Portal'
    },
    children: [
      {
        path: 'landing',
        component: LandingComponent
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'schools',
        canActivate: [PermissionGuard],
        data: {
          module: '/schools'
        },
        loadChildren: () => import('./pages/schools/schools.module').then(m => m.SchoolsModule)
      },
      {
        path: 'teachers',
        canActivate: [PermissionGuard],
        data: {
          module: '/teachers'
        },
        loadChildren: () => import('./pages/teachers/teachers.module').then(m => m.TeachersModule)
      },
      {
        path: 'students',
        canActivate: [PermissionGuard],
        data: {
          module: '/students'
        },
        loadChildren: () => import('./pages/students/students.module').then(m => m.StudentsModule)
      },
      {
        path: 'teacher-students',
        canActivate: [PermissionGuard],
        data: {
          module: '/teacher-students'
        },
        loadChildren: () => import('./pages/teacher-students/teacher-students.module').then(m => m.TeacherStudentsModule)
      },
      {
        path: 'classes',
        canActivate: [PermissionGuard],
        data: {
          module: '/classes'
        },
        loadChildren: () => import('./pages/classes/classes.module').then(m => m.ClassesModule)
      },
      {
        path: 'courses',
        canActivate: [PermissionGuard],
        data: {
          module: '/courses'
        },
        loadChildren: () => import('./pages/courses/courses.module').then(m => m.CoursesModule)
      },
      {
        path: 'exam',
        canActivate: [PermissionGuard],
        data: {
          module: '/exam'
        },
        loadChildren: () => import('./pages/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'rpp',
        canActivate: [PermissionGuard],
        data: {
          module: '/rpp'
        },
        loadChildren: () => import('./pages/rpp/rpp.module').then(m => m.RPPModule)
      },
      {
        path: 'class-schedules',
        canActivate: [PermissionGuard],
        data: {
          module: '/class-schedules'
        },
        loadChildren: () => import('./pages/class-schedules/class-schedules.module').then(m => m.ClassSchedulesModule)
      },
      {
        path: 'headmaster-class-schedules',
        // canActivate: [PermissionGuard],
        data: {
          module: '/headmaster-class-schedules'
        },
        loadChildren: () => import('./pages/headmaster-class-schedules/headmaster-class-schedules.module').then(m => m.HeadmasterClassSchedulesModule)
      },
      {
        path: 'homeroom-teacher',
        canActivate: [PermissionGuard],
        data: {
          module: '/homeroom-teacher'
        },
        loadChildren: () => import('./pages/homeroom-teacher/homeroom-teacher.module').then(m => m.HomeroomTeacherModule)
      },
      {
        path: 'final-grading',
        canActivate: [PermissionGuard],
        data: {
          module: '/final-grading'
        },
        loadChildren: () => import('./pages/final-grading/final-grading.module').then(m => m.FinalGradingModule)
      },
      {
        path: 'grading',
        canActivate: [PermissionGuard],
        data: {
          module: '/grading'
        },
        loadChildren: () => import('./pages/grading/grading.module').then(m => m.GradingModule)
      },
      {
        path: 'school-exam',
        canActivate: [PermissionGuard],
        data: {
          module: '/school-exam'
        },
        loadChildren: () => import('./pages/school-exam/school-exam.module').then(m => m.SchoolExamModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
