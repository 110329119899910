import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { ILocation } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LocationService {

  constructor(
    private http: HttpClient,
  ) { }

  getProvince(): Observable<ILocation[]> {    
    return this.http.get<ILocation[]>(`${environment.apiEndpoint}locations/province`);
  }

  getCity(id: string): Observable<ILocation[]> {
    const params = {
      parent: id
    };

    return this.http.get<ILocation[]>(`${environment.apiEndpoint}locations/kabupaten`, { params: params});
  }

  getDistrict(id: string): Observable<ILocation[]> {
    const params = {
      parent: id
    };

    return this.http.get<ILocation[]>(`${environment.apiEndpoint}locations/kecamatan`, { params: params });
  }

  getSubDistrict(id: string): Observable<ILocation[]> {
    const params = {
      parent: id
    };

    return this.http.get<ILocation[]>(`${environment.apiEndpoint}locations/kelurahan`, { params: params });
  }
}
