import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";
import { IDataTable, IStaff, IStaffRequest } from "../@interfaces";
import { Observable } from "rxjs";
import { ISchoolExam } from "../@interfaces/school-exam.interface";
import { IClass } from "../@interfaces";

@Injectable({
  providedIn: "root",
})
export class StaffService {
  constructor(private http: HttpClient) {}

  getList(page): Observable<IDataTable<IStaff>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IStaff>>(
      `${environment.apiEndpoint}staff`,
      { params: param }
    );
  }

  getRequestList(page): Observable<IDataTable<IStaffRequest>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }

    return this.http.get<IDataTable<IStaffRequest>>(
      `${environment.apiEndpoint}staff-request`,
      { params: param }
    );
  }

  approveStaff(id: string) {
    return this.http.patch(`${environment.apiEndpoint}staff/approve/${id}`, {});
  }

  rejectStaff(id: string) {
    return this.http.patch(`${environment.apiEndpoint}staff/reject/${id}`, {});
  }

  delete(id) {
    return this.http.delete(`${environment.apiEndpoint}staff/delete/${id}`, {});
  }

  requestSchool(params) {
    return this.http.post(`${environment.apiEndpoint}staff/request`, params);
  }

  getSchoolExam(page, selectedFilter): Observable<IDataTable<ISchoolExam>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
      ...selectedFilter,
    };

    if (page.q) {
      param.s = page.q;
    }

    return this.http.get<IDataTable<ISchoolExam>>(
      `${environment.apiEndpoint}exam/staff/list`,
      { params: param }
    );
  }

  getExamCategories() {
    return this.http.get(`${environment.apiEndpoint}exam-categories`);
  }

  unpublishExam(examId) {
    return this.http.patch(
      `${environment.apiEndpoint}exam/staff/unpublish/${examId}`,
      {}
    );
  }

  getListExam(page, searchValue): Observable<IDataTable<IClass>> {
    const param: any = {
      limit: page.size,
      page: page.pageNumber,
    };
    if (page.join) {
      param.join = page.join;
    }
    if (page.q) {
      param.s = page.q;
    }
    if (page.filter) {
      param.filter = page.filter;
    }
    if (page.sort) {
      param.sort = page.sort;
    }
    if (searchValue) {
      param.q = searchValue;
    }

    return this.http.get<IDataTable<IClass>>(
      `${environment.apiEndpoint}exam/teacher/list`,
      { params: param }
    );
  }

  addStaff(payload) {
    return this.http.post(
      `${environment.apiEndpoint}staff/add-staff-school`,
      payload
    );
  }
}
