import { FormGroup } from '@angular/forms';

// custom validator to check that youtube link fields valid
export function ValidFile(controlName: string, config: any) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];    

    if (control.errors && !control.errors.type) {
      // return if another validator has already found an error on the control
      return;
    }

    if(control.get('type').value && !config.acceptedTypes.includes(control.get('type').value)) {
      control.setErrors({ type: true });
    }
    else {
      control.setErrors(null);
    }

    if (control.errors && !control.errors.size) {
      // return if another validator has already found an error on the control
      return;
    }

    if(control.get('size').value && (control.get('size').value > config.maxSize)) {
      control.setErrors({ size: true });
    }
    else {
      control.setErrors(null);
    }

    if (control.errors && !control.errors.required) {
      // return if another validator has already found an error on the control
      return;
    }

    if(!control.get('dataUrl').value && config.required) {
      control.setErrors({ required: true });
    }
    else {
      control.setErrors(null);
    }
    
  }
}
