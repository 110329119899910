import { ISelectOption } from "@interfaces";

export const schoolTypesConstant: ISelectOption[] = [
    { value: "SD", text: "SD" },
    { value: "SMP", text: "SMP" },
    { value: "SMA", text: "SMA" },
    { value: "SMK", text: "SMK" },
    { value: "SLB", text: "SLB" }
];

export const pageLimitsConstant: ISelectOption[] = [
    { value: 10, text: "10" },
    { value: 20, text: "20" },
    { value: 50, text: "50" },
    { value: 100, text: "100" },
    { value: 250, text: "250" },
    { value: 500, text: "500" }

];

export const monthsConstant: ISelectOption[] = [
    { value: "Januari", text: "Januari" },
    { value: "Februari", text: "Februari" },
    { value: "Maret", text: "Maret" },
    { value: "April", text: "April" },
    { value: "Mei", text: "Mei" },
    { value: "Juni", text: "Juni" },
    { value: "Juli", text: "Juli" },
    { value: "Agustus", text: "Agustus" },
    { value: "September", text: "September" },
    { value: "Oktober", text: "Oktober" },
    { value: "November", text: "November" },
    { value: "Desember", text: "Desember" }
];

export const monthsWithNumberConstant: ISelectOption[] = [
    { value: 0, text: "Januari" },
    { value: 1, text: "Februari" },
    { value: 2, text: "Maret" },
    { value: 3, text: "April" },
    { value: 4, text: "Mei" },
    { value: 5, text: "Juni" },
    { value: 6, text: "Juli" },
    { value: 7, text: "Agustus" },
    { value: 8, text: "September" },
    { value: 9, text: "Oktober" },
    { value: 10, text: "November" },
    { value: 11, text: "Desember" }
];

export const civilStatusesConstant: ISelectOption[] = [
    { value: "Kawin", text: "Kawin" },
    { value: "Belum Kawin", text: "Belum Kawin" },
    { value: "Duda", text: "Duda" },
    { value: "Janda", text: "Janda" }
];

export const relationshipsConstant: ISelectOption[] = [
    { value: "Ayah", text: "Ayah" },
    { value: "Ibu", text: "Ibu" },
    { value: "Wali", text: "Wali" },
    { value: "Suami", text: "Suami" },
    { value: "Istri", text: "Istri" },
    { value: "Anak", text: "Anak" },
    { value: "Keponakan", text: "Keponakan" },
    { value: "Adik", text: "Adik" },
    { value: "Kakak", text: "Kakak" }
];

export const professionsConstant: ISelectOption[] = [
    { value: "PNS", text: "PNS" },
    { value: "Tentara", text: "Tentara" },
    { value: "Ibu Rumah Tangga", text: "Ibu Rumah Tangga" },
    { value: "Wiraswasta", text: "Wiraswasta" },
    { value: "Wirausaha", text: "Wirausaha" }
];

export const degreesConstant: ISelectOption[] = [
    { value: "SMP", text: "SMP" },
    { value: "SMA", text: "SMA" },
    { value: "D2", text: "D2" },
    { value: "D3", text: "D3" },
    { value: "D4 / S1", text: "D4 / S1" },
    { value: "S2", text: "S2" },
    { value: "S3", text: "S3" }
];

export const companyPositionsConstant: ISelectOption[] = [
    { value: "Kepala Sekolah", text: "Kepala Sekolah" },
    { value: "Guru", text: "Guru" },
    { value: "Staff / TU", text: "Staff / TU" }
];

export const companyExpertisesConstant: ISelectOption[] = [
    { value: "Agama", text: "Agama" },
    { value: "IPA", text: "IPA" },
    { value: "IPS", text: "IPS" },
    { value: "Matematika", text: "Matematika" },
    { value: "PJOK", text: "PJOK" },
    { value: "Bahasa Indonesia", text: "Bahasa Indonesia" },
    { value: "Bahasa Inggris", text: "Bahasa Inggris" },
    { value: "PPKN", text: "Pendidikan Pancasila dan Kewarganegaraan" },
    { value: "Seni budaya", text: "Seni budaya (Mulok)" },
    { value: "Prakarya", text: "Prakarya (Mulok)" }
];

export const companyStatusesConstant: ISelectOption[] = [
    { value: "PNS", text: "Pegawai Negeri Sipil" },
    { value: "Honorer", text: "Honorer" }
];

export const companySKTypesConstant: ISelectOption[] = [
    { value: "2A", text: "2A" },
    { value: "2B", text: "2B" },
    { value: "2C", text: "2C" },
    { value: "2D", text: "2D" },
    { value: "3A", text: "3A" },
    { value: "3B", text: "3B" },
    { value: "3C", text: "3C" },
    { value: "3D", text: "3D" },
    { value: "4A", text: "4A" },
    { value: "4B", text: "4B" },
    { value: "4C", text: "4C" },
    { value: "4D", text: "4D" },
    { value: "4E", text: "4E" }
];

export const religionsConstant: ISelectOption[] = [
    { text: 'Islam', value: 'Islam' },
    { text: 'Protestan', value: 'Protestan' },
    { text: 'Katolik', value: 'Katolik' },
    { text: 'Hindu', value: 'Hindu' },
    { text: 'Buddha', value: 'Buddha' },
    { text: 'Khonghucu', value: 'Khonghucu' },
    { text: 'Kepercayaan', value: 'Kepercayaan' }
];

export const achievementLevelsConstant: ISelectOption[] = [
    { text: 'Nasional / Internasional', value: 'Nasional / Internasional' },
    { text: 'Provinsi', value: 'Provinsi' },
    { text: 'Kecamatan', value: 'Kecamatan' },
    { text: 'Kabupaten', value: 'Kabupaten' }
];

export const achievementRanksConstant: ISelectOption[] = [
    { text: 'Juara 1', value: 'Juara 1' },
    { text: 'Juara 2', value: 'Juara 2' },
    { text: 'Juara 3', value: 'Juara 3' },
    { text: 'Juara Harapan 1', value: 'Juara Harapan 1' },
    { text: 'Juara Harapan 2', value: 'Juara Harapan 2' },
];

export const achievementTypesConstant: ISelectOption[] = [
    { text: 'Ujian Sekolah', value: 'Ujian Sekolah' },
    { text: 'Perorangan', value: 'Perorangan' },
    { text: 'Kelompok/Beregu sampai 5 orang', value: 'Kelompok/Beregu sampai 5 orang' },
    { text: 'Kelompok/Beregu sampai 10 orang', value: 'Kelompok/Beregu sampai 10 orang' },
    { text: 'Kelompok/Beregu lebih dari 10 orang - Anggota', value: 'Kelompok/Beregu lebih dari 10 orang - Anggota' },
    { text: 'Kelompok/Beregu lebih dari 10 orang - ketua', value: 'Kelompok/Beregu lebih dari 10 orang - ketua' }
];

export const assignmentTypesConstant: ISelectOption[] = [
    { text: 'Perorangan', value: 'personal' }
];

export const questionTypesConstant: ISelectOption[] = [
    { text: 'Pilihan Ganda', value: 'ganda' },
    { text: 'Essay', value: 'essay' },
    // { text: 'Flashcard', value: 'flashCard' } // temporary disabled
];

export const gradingTypesConstant: ISelectOption[] = [
    { value: 'pengetahuan', text: 'Pengetahuan' },
    { value: 'keterampilan', text: 'Keterampilan' }
];

export const gradeLevelsConstant: any = [
    {
        type: "SD",
        data: [
            { value: 1, text: "1 (Satu)" },
            { value: 2, text: "2 (Dua)" },
            { value: 3, text: "3 (Tiga)" },
            { value: 4, text: "4 (Empat)" },
            { value: 5, text: "5 (Lima)" },
            { value: 6, text: "6 (Enam)" }
        ]
    },
    {
        type: "SMP",
        data: [
            { value: 7, text: "7 (Tujuh)" },
            { value: 8, text: "8 (Delapan)" },
            { value: 9, text: "9 (Sembilan)" }
        ]
    },
    {
        type: "SMA",
        data: [
            { value: 10, text: "10 (Sepuluh)" },
            { value: 11, text: "11 (Sebelas)" },
            { value: 12, text: "12 (Dua Belas)" }
        ]
    },
    {
        type: "SMK",
        data: [
            { value: 10, text: "10 (Sepuluh)" },
            { value: 11, text: "11 (Sebelas)" },
            { value: 12, text: "12 (Dua Belas)" }
        ]
    },
    {
        type: "SLB",
        data: [
            { value: 1, text: "1 (Satu)" },
            { value: 2, text: "2 (Dua)" },
            { value: 3, text: "3 (Tiga)" },
            { value: 4, text: "4 (Empat)" },
            { value: 5, text: "5 (Lima)" },
            { value: 6, text: "6 (Enam)" },
            { value: 7, text: "7 (Tujuh)" },
            { value: 8, text: "8 (Delapan)" },
            { value: 9, text: "9 (Sembilan)" },
            { value: 10, text: "10 (Sepuluh)" },
            { value: 11, text: "11 (Sebelas)" },
            { value: 12, text: "12 (Dua Belas)" }
        ]
    }
];

export const gradesConstant: ISelectOption[] = [
    { value: "A", text: "A" },
    { value: "B", text: "B" },
    { value: "C", text: "C" },
    { value: "D", text: "D" },
    { value: "E", text: "E" }
];

export const termsConstant: ISelectOption[] = [
    { value: 1, text: "Ganjil" },
    { value: 2, text: "Genap" }
];

export const attendanceNotesConstant: ISelectOption[] = [
    { value: 'Hadir', text: 'Hadir' },
    { value: 'Sakit', text: 'Sakit' },
    { value: 'Izin', text: 'Izin' },
    { value: 'Alpha', text: 'Alpha' }
];

export const gendersConstant: ISelectOption[] = [
    { value: 'm', text: 'Laki - Laki' },
    { value: 'f', text: 'Perempuan' }
];

export const timeRangesConstant: ISelectOption[] = [
    { value: 'YESTERDAY', text: 'Hari Terakhir' },
    { value: '3_DAYS_AGO', text: '3 Hari Terakhir' },
    { value: '7_DAYS_AGO', text: '7 Hari Terakhir' },
    { value: '1_MONTH_AGO', text: '1 Bulan Terakhir' },
    { value: 'CUSTOM', text: 'CUSTOM' }
];

export const statusConstant: ISelectOption[] = [
    { value: 'register', text: 'Terdaftar' },
    { value: 'not register', text: 'Belum Terdaftar' }
];