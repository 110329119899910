import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as _ from 'lodash';

import { storageConstant } from '../@constant';
import { INavData } from '@coreui/angular';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private router: Router, public storage: StorageMap) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve) => {
        this.storage.get(storageConstant.menus).subscribe((menus:any[]) => {
          let menuList : INavData[] = [] 
          menus.forEach((obj : any) => {
            if (obj?.children?.length >= 1) {
              menuList.push(...obj.children)
            } else {
              menuList.push(obj)
            }
          })
          const permit = _.find(menuList, { url: next.data.module });
          if (permit) {
            resolve(true);
          } 
          else {
            this.router.navigate(['404']);
            resolve(false);
          }
        });
      });
    }

}
