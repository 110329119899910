import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";
import { IPromotion, IStatusPromotion } from "../@interfaces";

@Injectable({
  providedIn: "root",
})
export class HomeroomTeacherService {
  constructor(private http: HttpClient) {}

  getStudentList(params) {
    return this.http.get(`${environment.apiEndpoint}wali-kelas/list`, {
      params: params,
    });
  }

  getStudentDetail(id, params) {
    return this.http.get(
      `${environment.apiEndpoint}wali-kelas/detail-siswa/${id}`,
      { params: params }
    );
  }

  getCourseDetail(id, params) {
    return this.http.get(
      `${environment.apiEndpoint}wali-kelas/detail-harian/${id}`,
      { params: params }
    );
  }

  getGradingReport(id, params) {
    return this.http.get(
      `${environment.apiEndpoint}wali-kelas/report-nilai/${id}`,
      { params: params }
    );
  }

  getAttendanceReport(params) {
    return this.http.get(
      `${environment.apiEndpoint}wali-kelas/report-kehadiran`,
      { params: params }
    );
  }

  updateNote(id, params) {
    return this.http.put(
      `${environment.apiEndpoint}wali-kelas/add-notes/${id}`,
      params
    );
  }

  approveStudent(id) {
    return this.http.patch(
      `${environment.apiEndpoint}wali-kelas/approve-student/${id}`,
      {}
    );
  }

  rejectStudent(id) {
    return this.http.patch(
      `${environment.apiEndpoint}wali-kelas/reject-student/${id}`,
      {}
    );
  }

  addStudent(id, params) {
    return this.http.patch(
      `${environment.apiEndpoint}wali-kelas/add-student/${id}`,
      params
    );
  }

  removeStudent(id) {
    return this.http.patch(
      `${environment.apiEndpoint}wali-kelas/remove-student/${id}`,
      {}
    );
  }

  getStudentclassPromotion(id) {
    return this.http.get<IStatusPromotion>(
      `${environment.apiEndpoint}wali-kelas/class-promotion/student/${id}`,
      {}
    );
  }

  studentclassPromotion(id, body) {
    return this.http.patch<IPromotion>(
      `${environment.apiEndpoint}wali-kelas/grade-promotion/${id}`,
      body
    );
  }

  bulkSubmitStudent(idList: string[], status : string) {
    return this.http.patch(
      `${environment.apiEndpoint}wali-kelas/${status}-many-students`,
      { ids: idList }
    );
  }
}
