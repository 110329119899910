import { FormGroup, FormArray } from '@angular/forms';

// custom validator to check that at least one option is selected in form array
export function ValidScoreWeight(controlName: string, secondControlName: string) {
  return (formGroup: FormGroup) => {
    const control: any = formGroup.controls[controlName];
    const secondControl: any = formGroup.controls[secondControlName];

    if(control.errors && !control.errors.invalidScoreWeight) {
      return;
    }

    if(parseFloat(control.value.toString().replace(/,/g, '.')) > parseFloat(secondControl.value.toString().replace(/,/g, '.'))) {
      control.setErrors({ invalidScoreWeight: true });
    }
    else {
      control.setErrors(null);
    }
  }
}
