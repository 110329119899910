// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://dev-api.eduku.io/api/v1/',
  // apiEndpoint: 'http://localhost:3000/api/v1/',
  firebase: {
    apiKey: 'AIzaSyBEAf5P3uBau8x64pmJJDC_npxuZ3CxYcw',
    authDomain: 'eduku-72a13.firebaseapp.com',
    databaseURL: 'https://eduku-72a13.firebaseio.com',
    projectId: 'eduku-72a13',
    storageBucket: 'eduku-72a13.appspot.com',
    messagingSenderId: '51257122334',
    appId: "1:51257122334:web:93c2f5a4b458900cf4980f",
    measurementId: "G-5D0RZEKZNN"
  }
};
